﻿'use strict';

import numeral from 'numeral';

import axios from 'axios';

import {
    ADD_TO_CART_URL,
    REMOVE_CART_ITEM_URL,
    UPDATE_CART_ITEM_URL,
    $CART_ITEMS,
    $CART_ITEM_ELEMENT_BY_ID,
    $CART_QUANTITY_ELEMENTS,
    $SIDECART_ITEM_CONTAINER,
    $CART_ITEM_PRICE,
    $CART_ITEM_SUBTOTAL,
    $SINGLE_CART_ITEM_SUBTOTAL,
    $CART_ITEM_QTY,
    $CART_ITEM_DELETE_ACTION_ELEMENT,
    $SIDE_CART_ITEM_PRICE,
    $SIDE_CART_ITEM_QTY,
    $CART_ITEM_CONTAINER,
    $CART_CLASSIFICATION_PRICE
} from './constants';

import * as UTILS from './utils';

import { TOASTER_ERROR_BG_COLOR } from '../../constants';

import Cookies from "js-cookie";

import {
    getClassification
} from './product-classification';


// resetting address
const $RESET_CART_ADDRESS = () => $('[data-action="reset-cart-address"]');

const RESET_ADDRESS_URL = '/umbraco/surface/cart/resetaddress';


// resetting courier
const $RESET_CART_COURIER = () => $('[data-action="reset-cart-courier"]');

const RESET_COURIER_URL = '/umbraco/surface/cart/resetcourier';


// resetting payment
const $RESET_CART_PAYMENT = () => $('[data-action="reset-cart-payment"]');

const RESET_PAYMENT_URL = '/umbraco/surface/cart/resetpayment';

var symbol = Cookies.get('__currencySymbol');


// adding item to cart
export function AddToCart(productId, chosenVariant, quantity, iswithaddress) {

    let dataClassification = getClassification();

    if (chosenVariant && productId && quantity) {
        // data to be sent
        let data = {
            productId: productId,
            variantId: chosenVariant,
            qty: quantity,
            classification: dataClassification
        }

        if (iswithaddress) {
            return axios.post(ADD_TO_CART_WITH_ADDRESS_URL, data);
        }
        else {
            return axios.post(ADD_TO_CART_URL, data);
        }
    }
    else if (chosenVariant == undefined || chosenVariant == null) {
        Notify('Please choose a variant!');
        return;
    }
    else if (productId == undefined || productId == null) {
        Notify('Please choose a product!');
        return;
    }
    else {
        Notify('Please specify the quantity!');
        return;
    }
}

// adding item to cart with address
export function AddToCartWithAddress(productId, chosenVariant, quantity, iswithaddress) {

    let dataClassification = getClassification();

    if (chosenVariant && productId && quantity) {
        // data to be sent
        let data = {
            productId: productId,
            variantId: chosenVariant,
            qty: quantity,
            classification: dataClassification
        }

        if (iswithaddress) {
            return axios.post(ADD_TO_CART_WITH_ADDRESS_URL, data);
        }
        else {
            return axios.post(ADD_TO_CART_URL, data);
        }
    }
    else if (chosenVariant == undefined || chosenVariant == null) {
        Notify('Please choose a variant!');
        return;
    }
    else if (productId == undefined || productId == null) {
        Notify('Please choose a product!');
        return;
    }
    else {
        Notify('Please specify the quantity!');
        return;
    }
}

// deleting cart item
export function DeleteCartItem(id, isUnique = true) {
    if (id) {
        let jqxhr = $.post(REMOVE_CART_ITEM_URL, { id, isUnique });

        jqxhr
            .always(() => {
                if (jqxhr.status == 200) {
                    let cartItem = $CART_ITEM_ELEMENT_BY_ID(jqxhr.responseJSON.id);

                    // remove the cart item
                    cartItem.remove();

                    // update the quantity dispaly
                    $CART_QUANTITY_ELEMENTS().text(jqxhr.responseJSON.quantity);

                    // populate the cart item
                    if ($CART_ITEMS().length <= 0) {
                        // attach view of empty cart on side cart
                        $SIDECART_ITEM_CONTAINER().empty().append(UTILS.EMPTY_SIDECART_ELEMENT_STRING);

                        // attach view of empty cart on cart
                        $CART_ITEM_CONTAINER().empty().append(UTILS.EMPTY_CART_ELEMENT_STRING);
                    }

                    // calculate cart
                    CalculateCart();
                }
                else if (jqxhr.status == 400) {
                    Notify(jqxhr.statusText, 'error');
                }
            });
    }
}

// calculating cart
export function CalculateCart() {
    let subtotal = 0;

    let cartItemPrices = $CART_ITEM_PRICE();

    if (cartItemPrices.length <= 0) {
        cartItemPrices = $SIDE_CART_ITEM_PRICE();
    }

    if (cartItemPrices.length) {
        cartItemPrices.each(function (i) {
            let qty = $CART_ITEM_QTY().eq(i);

            if (qty.length <= 0) {
                qty = $SIDE_CART_ITEM_QTY().eq(i);
            }

            // element for subtotal of single item
            const singleItemSubtotalElem = $SINGLE_CART_ITEM_SUBTOTAL().eq(i);

            // get classification price data
            let cartClassificationPrice = $CART_CLASSIFICATION_PRICE().eq(i);
            let classificationPrice = 0;

            if (cartClassificationPrice.length) {
                    classificationPrice = cartClassificationPrice.data('classificationPrice');
            }
            
            // subtotal of single item
            const singleItemSubtotal = parseFloat(($(this).data('price')) + classificationPrice) * parseFloat(qty.data('qty'));

            try {

                // updating data
                singleItemSubtotalElem.data('price', singleItemSubtotal);

                // updating view
                singleItemSubtotalElem.text(symbol + ' ' + numeral(singleItemSubtotal).format('0,0.00'));
            } catch (e){
                //
            }

            // increment the subtotal
            subtotal += singleItemSubtotal;
        });
    }

    $CART_ITEM_SUBTOTAL().data('price', subtotal);

    $CART_ITEM_SUBTOTAL().text(symbol + ' ' + numeral(subtotal).format('0,0.00'));
}

// updating item on cart
export function UpdateCartItem(id, quantity, isUnique = true) {
    if (id) {
        let jqxhr = $.post(UPDATE_CART_ITEM_URL, { id, quantity, isUnique });

        jqxhr
            .always(() => {
                if (jqxhr.status == 200) {
                    // updating the stock data
                    $(`[data-identity="cart-item-qty"][data-id="${jqxhr.responseJSON.id}"]`).data('qty', jqxhr.responseJSON.qty);

                    let newTotal = 0;

                    $CART_ITEM_QTY().each(function () {
                        let qty = parseInt($(this).data('qty'));

                        newTotal += qty;
                    });

                    // change the cart total quantity display
                    $CART_QUANTITY_ELEMENTS().text(newTotal);

                    // recalculate total
                    CalculateCart();
                }
                else if (jqxhr.status == 400) {
                    // Toaster
                    Toaster({
                        text: jqxhr.statusText,
                        gravity: 'bottom',
                        position: 'right',
                        duration: 5000,
                        backgroundColor: TOASTER_ERROR_BG_COLOR
                    });
                }
            });
    }
}

$CART_ITEM_DELETE_ACTION_ELEMENT()
    .off()
    .click(function () {
        let id = $(this).data('id');
        let isUnique = $(this).data('isUnique');
        let prompt = $(this).data('prompt');

        let itemInfo = {
            imageUrl: $(`[data-identity="cart-item-image"][data-id="${id}"]`).data('imageUrl'),
            productName: $(`[data-identity="cart-item-name"][data-id="${id}"]`).data('name')
        };

        if (prompt) {
            window[prompt].call(this, itemInfo)
                .then(res => {

                    if (res) {
                        if (isUnique != undefined && isUnique != null) {
                            DeleteCartItem(id)
                        }
                        else {
                            DeleteCartItem(id, isUnique);
                        }
                    }

                })
        }

    });

$RESET_CART_ADDRESS()
    .off()
    .click(function () {

        axios.put(RESET_ADDRESS_URL)
            .then(res => {

                window.location.reload();

            })

    })

$RESET_CART_COURIER()
    .off()
    .click(function () {

        axios.put(RESET_COURIER_URL)
            .then(res => {

                window.location.reload();

            })

    })

$RESET_CART_PAYMENT()
    .off()
    .click(function () {

        axios.put(RESET_PAYMENT_URL)
            .then(res => {

                window.location.reload();

            })

    })
