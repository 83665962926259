﻿'use strict';

import Cookies from "js-cookie";

var symbol = Cookies.get('__currencySymbol');

const $RADIO_CLASSIFICATION = () => $('[data-identity="product-classification"]');

const $SELECTED_CLASSIFICATION_ROW = () => $('tr[data-identity="selected-classification"]');

const $SUMMARY_TOTAL = () => $('td[data-identity="summary-total"]');

$RADIO_CLASSIFICATION().change(function () {

    let classificationId = $(this).data('id');
    let classificationName = $(this).data('name');
    let classificationPrice = $(this).data('price');
    let categoryId = $(this).attr('name');
    let total_price = 0;
    let price = 0;

    // on change radio update data on summary
    $SELECTED_CLASSIFICATION_ROW().each(function () {

        let selectedCategoryId = $(this).data('categoryId');

        if (categoryId == selectedCategoryId) {

            $(this).find('td[data-identity="summary-name"]').html(classificationName);

            $(this).find('td[data-identity="summary-price"]').html(symbol + " " + classificationPrice);

            $(this).data('classificationId', classificationId);

            $(this).data('classificationName', classificationName);

            $(this).data('classificationPrice', classificationPrice);

        }

        price = parseFloat($(this).data('classificationPrice'));

        total_price += price;

        $SUMMARY_TOTAL().html(symbol + " " + total_price.toString());

        $SUMMARY_TOTAL().data('total', total_price);

    })
})

//set data on summary if material checked default
$RADIO_CLASSIFICATION().each(function () {
    
    let classificationId = $(this).data('id');
    let classificationName = $(this).data('name');
    let classificationPrice = $(this).data('price');
    let categoryId = $(this).attr('name');
    let total_price = 0;
    let price = 0;

    if ($(this).is(':checked')) {

        $SELECTED_CLASSIFICATION_ROW().each(function () {

            let selectedCategoryId = $(this).data('categoryId');

            if (categoryId == selectedCategoryId) {

                $(this).find('td[data-identity="summary-name"]').html(classificationName);

                $(this).find('td[data-identity="summary-price"]').html(symbol + " " + classificationPrice);

                $(this).data('classificationId', classificationId);

                $(this).data('classificationName', classificationName);

                $(this).data('classificationPrice', classificationPrice);

            }

            price = Number(($(this).data('classificationPrice').replace(/[^0-9.-]+/g, "")));

            total_price += price;

            $SUMMARY_TOTAL().html(symbol + " " + total_price);

            $SUMMARY_TOTAL().data('total', $SUMMARY_TOTAL);
        })
    }
})

//get classification data on summary
export function getClassification() {

    let classificationList = [];

    $SELECTED_CLASSIFICATION_ROW().each(function () {

        let price = parseFloat($(this).data('classificationPrice'));

        let categoryId = $(this).data('categoryId');

        let classificationId = $(this).data('classificationId');

        let classificationName = $(this).data('classificationName');

        let categoryName = $(this).data('categoryName');

        let classification = {
            Id: classificationId,
            CategoryId: categoryId,
            Quantity: 1,
            AdditionalPrice: price,
            InvoiceItemId: "",
            Name: classificationName,
            CategoryName: categoryName
        };

        if (classification.Id) {
            classificationList.push(classification);
        }

        if (classificationList == undefined) {
            classificationList = [];
        }
    });

    let classificationToCart = {
        ClassificationList: classificationList,
        Total: $SUMMARY_TOTAL().data('total')
    };

    return classificationToCart;
}